/**
TABLE OF CONTENTS
=================
1. Reset
2. Global
  2.1 Nav
3. Dashboard
  3.1. Left Panel
  3.2  Middle Panel
  3.3  Right Panel
4. Partner Form
5. Login/Register
6. Modals
7. Add Rider
8. Rider Dashboard
9. PT1 Selection
10. Desktop
11. Tickets
*/

/*** 1. Reset ***/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


/*** 2. Global ***/
html, body, #root, #root > div, main{
  height: 100%;
  background-color: #FAFAFA;
}

@font-face {
  font-family: 'Rubik';
  src: url('fonts/Rubik/Rubik-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RubikThin';
  src: url('fonts/Rubik/static/Rubik-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Rubik', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
label,
select,
textarea,
button{
  font-family: 'Rubik', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root{
  width:100%;
}

main{
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #FAFAFA;
}

input[type="button"],
input[type="submit"],
button{
  cursor: pointer;
}

.semanticVisible{
  border: 0; 
  clip: rect(0 0 0 0); 
  height: 1px; 
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
button{
  border-radius: 0.2em;
  border: 0.08rem #000 solid;
}

b{
  font-weight: bold;
}

.noPoint{
  pointer-events: none;
  opacity: 0.4;
}
.errorSpan{ 
  color: #ca2222;
}

.errorField{
  border: 0.1rem solid #ca2222 !important;
}

.errorLabelPhoneMinMax::after{
  color: #ca2222;
  content: " - Must be numbers between 10-24 digits";
 }
 
 .errorLabelPhoneNotValid::after{
  color: #ca2222;
  content: " - Not a valid phone number";
 }

 .errorLabel::after{
  color: #ca2222;
  content: " - Required Field";
 }
 
.required:before{
  content:"* ";
}

label > strong{
  color: #ca2222;
}

textarea{
  resize:vertical;
}

/** 2.1 Nav  **/
nav{
  width:100%;
  height: 4.4rem;
  background-color: #FAFAFA;
  float: right;
  right: 0;
  display: flex;
  align-items: center;
  border-bottom: solid 0.1rem #333;
}
nav > ul{
  width: 100%;
  padding: 0 1rem 0 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 3;
}
nav li{
  font-size: 1.5rem;
  position: relative;
}

nav li:nth-child(1){
  margin-right: auto;
}

nav li:nth-child(2) button{
  font-size: 1rem;
  padding: 0.3rem;
  border: 0.1rem solid #08326B;
}

nav li:nth-child(2) button span{
    vertical-align: middle;
}

nav li:nth-child(2) button svg{
  height: 1.5rem !important;
  width: 1.5rem !important;
  transform: rotate(180deg);
  vertical-align: middle;
}

nav li button{
  background-color: #FAFAFA;
  color:#08326B;
  border:none;
  margin:0;
  cursor: pointer;
}

nav li button svg{
  height: 2.5rem !important;
  width: 2.5rem !important;
}

nav li > ul{
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color:#FAFAFA;
  right:0;
  top:0;
  margin: 3.7rem 0 0 0;
  min-width: 10rem;
  white-space:nowrap;
  -webkit-box-shadow: -2px 4px 3px 1px rgba(0,0,0,0.5); 
  box-shadow: -2px 4px 10px 1px rgba(0,0,0,0.3);
}

nav li > ul li{
  font-size: 1rem;
  padding: 1.25rem 0 1.25rem 1rem;
  border-top: #000 solid 0.06rem;
  position: static;
  display: inline-block;
  text-align: left;
  width: 100%;
  background-color:#FAFAFA;

}
nav li > ul li:last-child{
  margin-left: auto;
  float: right;
}

nav li > ul li strong{
  font-weight: bold;
}

nav li > ul li button{
  color:#000;
  padding: 0;
  margin:0;
}

nav li > ul li button a{
  color:#000;
  font-size:1rem;
}


/*** 3. Dashboard***/
.dashboard{
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

/** 3.1 Left Panel**/
.riderPanel{
  width:100%;
  float: left;
}
.riderPanel::after{
  width: 0.1rem;
  height: 100vh;
  background-color:#333 ;
  position: absolute;
  z-index: -1;
}

/* Riders header */
.riderPanel > h1{
  font-size: 1.5rem;
  font-weight: bold;
  color:#FFF;
  text-align: center;
  padding: 1.45rem 0;
  border-bottom: 0.1rem #FFF solid;
  background-color:#08326B ;
  position: relative;
}

.riderPanel > h1 svg{
  width: 2rem !important;
  height: 2rem !important;
  padding: 0 1rem 0 0;
  position:absolute;
  right:0;
  top: 0;
  transform: translate(0, 65%);
  cursor: pointer;
}

/* conditional Riders search box */
.riderPanel > input{
  font-size: 1.5rem;
  font-weight: bold;
  color:#FFF;
  padding: 1.32rem 1rem;
  margin: none;
  border:none;
  width:100%;
  background-color:#08326B ;
  border:none;
  border-bottom: 0.1rem #FFF solid;
}

/* List of riders */
.riderPanel ul{
  width: 100%;
  margin:0 5% 0 0;
  overflow-y:auto;
  overflow-x:hidden;
  border-radius: 0.2em;
  position: relative;
  height: 30vh;
}

/* if there are no riders yet */
.riderPanel .noRiders{
  flex-direction: column;
  align-items: flex-start;
}

.riderPanel .noRiders p{
  margin: 1rem 0;
}

.riderPanel .noRiders b{
  font-weight: bold;
  color:#97BB3D;
}

.riderPanel .noRiders input{
  border: none;
  color:#97BB3D;
  background-color: transparent;
  padding: 0 0 0 0.3rem;
  font-size: 1rem;
  text-decoration: underline;
  font-weight: bold;
  line-height: 1.5rem;
}

/* Each rider */
.riderPanel li{
  padding:0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1rem #FFF solid;
  color:#FFF;
  cursor: pointer;
}

.riderPanel li:nth-child(even){
  background-color: rgba(108, 192, 228, 0.15);
}

/* open rider menu button */
.riderPanel li button{
  /* padding:1rem; */
  border-radius: 0.2rem;
  border: none;
  position: relative;
  color:#FAFAFA;
  background-color: transparent;
}

.riderPanel li button svg{
  width: 2.3rem;
  height: 2.3rem;
}

.riderPanel li button:hover{
  color:#97BB3D;
}

/* Add rider button*/
.riderPanel > button{
  width: 40%;
  padding:1rem 0.5rem;
  font-size:1rem;
  float: right;
  font-weight: bold;
  text-decoration: underline;
  border: none;
  border-radius:  0 0 0.2rem 0.2rem;
  background-color: #97BB3D;
  color: #FFF;
}


/* Rider Menu */
.riderPanel .riderListMenu{
  display: flex;
  flex-direction: column;
  align-items:flex-start;
}

.riderPanel > .riderListMenu > ul{
 background-color: #17487d;
}

.riderPanel .riderListMenu ul li{
  justify-content: flex-start;
  background-color: transparent;
  border:none;
  padding-left: 0;
  margin: 1rem 0;
  cursor: pointer;
}

.riderListMenu ul li span{
  font-weight: bold;
}

.riderPanel .riderListMenu button{
  margin: 1rem 0;
  width: 100%;
  color: #FAFAFA;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: #97BB3D;
  padding: 1rem 0.5rem;
}

.riderPanel .riderListMenu button svg{
  vertical-align: middle;
  color: #FAFAFA;
  padding-left: 0.5rem;
  height: 1.5rem;
  width: 1.5rem;
}

.riderMenuOpen button svg{
  color: #97BB3D;
}

.riderListMenu fieldset{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.riderListMenu fieldset button{
  width: auto;
  max-width: 45%;
}

.riderPanel .riderListMenu button:hover{
  color:#FAFAFA;
  background-color: #88a53e;
}

.riderListMenu fieldset button svg{
  padding-left: 0.2rem;
}


/* addRiderModal */

.riderPanel dialog form fieldset{
  margin-bottom: 2rem;
}

.riderPanel dialog form fieldset label{
  float: none;
}

.riderPanel dialog form fieldset input{
  margin-bottom: 0 !important;
}

.riderPanel dialog form fieldset ul{
  max-height:18rem;
  padding:0;
  margin: 0;
  margin-left: 2rem;
  width:calc(100% - 9rem);
}

.riderPanel dialog form fieldset li{
  background-color: #FFF !important;
  border:none;
  border-bottom: #000 solid 0.01rem;
  padding: 0;
  margin: 0;
  float: left;
  width: 100%;
  color:#000;
  text-align: left;
}

.riderPanel dialog form fieldset ul button{
  background-color: #FFF;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 1.25rem 0.5rem;
  color:#000;
}

.riderPanel dialog form fieldset ul button:hover{
  background-color: #1e90ff;
  color:#FFF;
}

.riderPanel dialog .locationFields > button{
  background-color: #08326B;
  color: #FFF;
  box-shadow: none;
  border: none;
  border-radius: 0.2rem;
  margin-left: 2rem;
  padding:1rem;
} 

.riderPanel #toggleRiderPanel{
  display: none;
}

/* . fieldset{

} */

.addRiderModal .radioContainer{
  margin-bottom: 1.5rem !important;
  display: flex;
  justify-content: flex-start;
  margin-left: 2rem;
  width: 83%;
  padding-bottom: 1.5rem;
}
.addRiderModal .radioContainer legend{
  margin-bottom: 1.5rem;
}

.addRiderModal .radioContainer input{
  width: auto;
  transform: scale(1.5);
  padding: 1rem;
  margin-left: 0.3rem !important;
  vertical-align: middle;
  min-width: auto !important;
}
.addRiderModal .radioContainer label{
  padding: 0.4rem 4rem 0.2rem 0;
  vertical-align: middle;
  margin:0 !important;
}

.addRiderModal .phoneInput{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 3rem;
}

.addRiderModal .phoneInput input[type=button],
.addRiderModal .phoneInput label:nth-child(4){
  margin-top: 1.2rem !important;
}
/** 3.2 Middle Panel**/
.itinsPanel{
  width:100%;
  height: 100%;
}

/* panel header */
.itinsPanel > ul:first-child{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin:1rem 0;
}
.itinsPanel > ul:first-child li{
  width: 33.2%;
}
.itinsPanel > ul li:hover input{
  background-color:#17487d;
}

.itinsPanel > ul:first-child input{
  padding:1.2rem 0.5rem;
  background-color: #08326B;
  color: #FFF;
  font-weight: bold;
  border:none;
  box-shadow: none;
  font-size:1rem;
  width: 100%;
}

.itinsPanel ul:first-child .subSelected{
  background-color: #97BB3D;
  color: #08326B;
}

.itinsPanel > p{
  /* text-align: center; */
  padding:3rem 0;
}

.itinsPanel .noItins{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:3rem 0;
}

.itinsPanel .noItins p{
  line-height: 125%;
  font-weight: bold;
}

.itinsPanel .noItins ol{
  list-style: decimal;
  text-align: left;
  padding-left: 1rem;
  line-height: 125%;
  font-weight: normal;
}

.itinsPanel table{
  width: 100%;
  background-color: #FAFAFA;
  color:#333;
  border-spacing:0 1rem;
  border-collapse:separate; 
  max-height: 60%;
  font-size: 0.8rem;
}
/* each ticket */
.itinsPanel tr{
  border-radius:0.2rem;
  border:none;
  text-align: left;
  background-color: #FFF;
  -webkit-box-shadow: 5px 5px 5px -2px rgba(8,50,107,0.1); 
  box-shadow: 5px 5px 5px -2px rgba(8,50,107,0.1);
}

.itinsPanel thead tr{
  border:none;
  background-color: #FAFAFA;
  box-shadow: none;
}

.itinsPanel td,
.itinsPanel th{
  vertical-align: middle;
  padding: 0;
}

.itinsPanel td:first-child{
  padding: 1.3% 0 1.3% 1rem;
}

.itinsPanel th{
  padding: 0.5rem 0;
  font-weight: bold;
}

.itinsPanel th:first-child{
  padding: 0.5rem 0 0.5rem 1rem;
}

.itinsPanel td ul li:first-child{
  padding-bottom: 2.5%;
}

/* info button */
.itinsPanel button{
  font-size: 0.6rem;
  font-weight: bold;
  padding:0.2rem 0.5rem;
  border-radius: 100%;
  border:none;
  background-color: #08326B;
  color: #FFF;
  font-style: italic;
}

.itinsPanel > ul:last-child{
  width: 100%;
  display: flex;
  justify-content: space-between;
}


.itinsPanel > ul:last-child button{
  padding:1rem 2.5rem;
  background-color: #08326B;
  color: #FFF;
  font-weight: bold;
  border-radius: 0;
  vertical-align: middle;
  font-style: normal;
}

.itinsPanel button:hover{
  background-color: #17487d;
}

.itinsPanel > ul:last-child button svg{
  vertical-align: middle;
  color:#FFF;
  height:1.3rem;
}

.itinsPanel dialog label{
  clear: both;
  margin:0.5rem 2rem 3rem;
  width: calc(100% - 9rem);
}

.itinsPanel dialog .locationFields label{
  float: none;
}

.itinsPanel dialog .locationFields input{
  margin-bottom: 0 !important;
}

.itinsPanel dialog .searchResults{
  background-color: #FFF;
  max-height: 13rem;
  overflow-y: auto;
  margin:0.5rem 2rem 3rem;
  width: calc(100% - 9rem);
}

.itinsPanel dialog .searchResults ul{
  margin: 0;
}

.itinsPanel dialog .searchResults button{
  font-weight: normal ;
  text-decoration: none;
  font-style: normal;
  border: none;
  border-radius: 0.2rem;
  background-color: #FFF;
  border:none;
  border-bottom: #000 solid 0.01rem;
  padding: 1.25rem 0.5rem;
  margin: 0;
  float: left;
  width: 100%;
  color:#000;
  text-align: left;
}

.itinsPanel dialog > article{
  margin-bottom: 1rem;
  padding-top: 1rem;
}

.itinsPanel dialog .canceled,
.itinsPanel dialog .canceled input,
.itinsPanel dialog .canceled select,
.itinsPanel dialog .canceled textarea{
  background-color: #f0c1c1;
}

.partnerForm .searchResults button:hover,
.partnerForm option:hover{
  background-color: #1e90ff;
  color:#FFF;
}

.itinsPanel dialog article > p{
  float: right;
  vertical-align: middle;
  margin: 0 4rem;
}

.itinsPanel dialog article input[name="cancel"]{
  background-color: #ca2222;
  float: left;
  margin: 0 2rem 1rem 2rem!important;
  width: calc(100% - 6rem) !important;
}

.itinsPanel dialog h1{
  padding-top: 0;
  padding-bottom: 0;
}

.itinsPanel dialog h1 input{
  /* padding: 0.8rem 1rem !important; */
  vertical-align: middle;
  margin-top:0.8rem !important;
  margin-bottom: 0.8rem !important;
}
.itinsPanel dialog h1 button{
  margin-top: 1.2rem !important;
}

/*** 4. Forms ***/
.bookingChoice{
  margin:1rem auto 0;
  padding: 5rem;
  background-color:#FFF;
  border-radius: 0.2em;
  border-top: 0.2em solid #08326B;
}

.bookingChoice h1{
  text-align: center;
  font-size: 3rem;
  margin-bottom: 2rem;
}

.bookingChoice input{
  color:#FFF;
  background-color: #08326B;
  padding:3rem;
  border: none;
  font-size: 1.5rem;
  margin: 2rem;
}

.bookingChoice input:hover{
  background-color: #97BB3D;
}

.form{
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  background-color: #FAFAFA;
}

.form form{
  width:90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding:0 2rem;
  background-color: #FFF;
  border-radius: 0.2em;
  border-top: 0.2em solid #08326B;
}

/** PartnerForm **/
.bookingForm label, 
.bookingForm input[type="submit"]{
  margin:4rem 0 1.25rem;
}

.bookingForm button,
.bookingForm input[type="submit"]{
  background-color: #08326B;
  color:#FFF;
  box-shadow: none;
  border: none;
  border-radius: 0.2rem;
}

.bookingForm button:hover,
.bookingForm input[type="submit"]:hover{
  background-color: #17487d;
}

.bookingForm input,
.bookingForm select,
.bookingForm button,
.bookingForm textarea{
  padding:1rem 0.5rem;
  width:100%;
  max-width:40rem;
}

.partnerForm fieldset,
.bookingForm .phones{
  border: none;
  padding:0;
  width:100%;
  max-width:40rem;
}



.bookingForm fieldset label,
.bookingForm fieldset input,
.bookingForm fieldset fieldset,
.bookingForm fieldset button,
.bookingForm fieldset ul{
  clear: both;
  float: left;
}

.bookingForm fieldset button{
  margin-top: 1.25rem;
}

.bookingForm fieldset ul{
  width: 100%;
}

.fieldTop{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}
.fieldTop button{
  width: auto;
  margin: 0 0 2rem;
}

.fieldTop h1{
  font-size: 2rem;
  padding:0 0 1rem;
  border-bottom: 0.1rem solid #08326B;
  width: 100%;
}

.bookingForm .searchResults{
  border: 0.01rem solid #08326B;
  border-top: 0.04rem solid #08326B;
  background-color: #FFF;
  max-height: 31.25rem; /*1em text + 1.25 line height * 5*/
  overflow-y: auto;
  width: 100%;
}

.bookingForm .locationFields,
.bookingForm .locationFields fieldset{
  width: 100%;
  max-width:40rem;
}

.bookingForm .locationFields fieldset input{
  border: none;
}

.locationFields > button{
  width: auto;
  padding:1rem 0.5rem !important;
  border-radius: 0;
}

.bookingForm .searchResults button{
  background-color: #FFF;
  border:none;
  border-bottom: #000 solid 0.01rem;
  padding: 1.25rem 0.5rem;
  margin: 0;
  float: left;
  width: 100%;
  color:#000;
  text-align: left;
}


.bookingForm .searchResults button:hover,
.bookingForm option:hover{
  background-color: #1e90ff;
  color:#FFF;
}

.bookingForm .searchResults li:last-child button{
  border:none;
}

.bookingForm input,
.bookingForm select,
.bookingForm textarea{
  border:none;
  border-bottom: 0.1em solid #08326B;
  padding-bottom: 1.1rem;
}

.bookingForm input:focus,
.bookingForm select:focus,
.bookingForm textarea:focus{
  border:none;
  border-bottom: 0.2em solid #97BB3D;
}

/* .bookingForm dialog{
  top:auto;
} */

.bookingForm dialog input,
.bookingForm dialog select,
.bookingForm dialog label{
  float: left;
  margin:0.5rem 2rem 3rem;
  width: calc(100% - 9rem);
}

.bookingForm input[type="date"]{
  width: auto;
  min-width: 30%;
}

.bookingForm input[type="checkbox"]{
  width:auto;
  transform: scale(1.5);
}

.bookingForm .phoneInput{
  width: 100%;
}

.bookingForm .phoneInput input[type="tel"]{ 
  max-width: 39rem;
}

.bookingForm .phoneInput input[type="button"],
.bookingForm #secondPhoneButton{ 
  border-radius: 0.2rem;
  background-color: #08326B;
  color:#FFF;
  border: none;
  margin: 0.5rem auto 0;
}

.bookingForm .phoneInput input[type="button"]{
  background-color: #97BB3D;
  
}

.bookingForm .phoneInput input[type="button"]:hover{
  background-color: #88a53e;
}

.bookingForm #secondPhoneButton:hover{
  background-color: #17487d;
}

.bookingForm #secondPhoneButton{ 
  margin-top: 2rem;
}


.bookingForm .returnFields{
  border: 0.1rem solid #08326B !important;
  padding: 0 2rem 3rem 2rem;
  margin: 2rem 0 0 -2rem ;
}


.bookingForm input:disabled{
  background-color:#c7c7c7;
}

/** Recurring Form **/
.recurringTripPicker{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - 2rem);
  /* max-width: 38rem; */
  border: 0.01rem solid #08326B;
  margin: 4rem 0 1rem;
  padding: 1rem;
  position: relative;
  border-radius: 0.2rem;
}

.recurringTripPicker > button:nth-child(1){
  position: absolute;
  width: 3.5rem;
  background-color: transparent;
  color:#08326B;
  right:0;
  top:0;
  margin:-1rem 0.5rem 0;
}

.recurringTripPicker > button:nth-child(1) svg{
  width: 100%;
  height: 100%;
}

.recurringTripPicker legend strong{
  color: #ca2222;
}

.recurringTripPicker fieldset:first-of-type{
  width: 100%;
}

.recurringTripPicker fieldset:first-of-type label{
  color:#FFF;
  background-color: #08326B;
  display: flex;
  flex-direction: column;
  align-items: center;
  float: left;
  width: 14.285%;
  padding:2rem 0;
  margin-top: 0;
  clear: none;
  cursor: pointer;
}

.recurringTripPicker fieldset:first-of-type label:hover{
  background-color: #97BB3D;
}

.recurringTripPicker fieldset:first-of-type label input{
  margin: 1rem 0 0;
  transform: scale(1.5);
}

.recurringTripPicker fieldset:first-of-type label:nth-child(1){
  width: 100%;
  float: left;
  color: #000;
  background-color: transparent;
  display: inline;
  padding: 1rem 0 0.5rem;
}

.recurringTripPicker label{
  margin: 2rem 0 0.5rem;
}

.recurringTripPicker select,
.recurringTripPicker input{
  padding: 1rem;
  padding-left: initial;
  padding-right: initial;
}

.recurringTripPicker .timePicker{
  width: 100%;
}

.recurringTripPicker .radioChecked{
  background-color: #97BB3D !important;
}

.recurringTripsForm input[type="button"]{
  background-color: #08326B;
  color:#FFF;
  width: 100%;
  border: none;
  border-radius: 0.2rem;
  }

.recurringTripsForm input[type="button"]:hover{
  background-color: #97BB3D;
}

/*** Time Picker ****/
.timePicker{
  display: flex;
}

.timePicker select{
  width:30%;
}

.timePicker span{
  padding: 1rem 0.5rem;
  font-weight: bold;
}


/*** 5. Login/Register ***/
.entry{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.entry form{
  display: flex;
  flex-direction: column;
  max-width: 90%;
}

.entry form h1{
  font-size: 2.5rem;
  font-weight: bold;
  padding: 0 0 1rem 0;
  border-bottom: 0.2rem solid #08326B;
}

.entry form input{
  padding:1rem 1rem 1rem 0.5rem;
  border:none;
  border-bottom: 0.1rem solid #08326B;
  margin: 4rem 0 0.5rem 0;
  font-size: 1.5rem;
  background-color: #FAFAFA;
}

.entry form input[type="submit"]{
  border:none;
  background-color: #08326B;
  color:#FFF;
  padding: 1rem;
}

.entry form p{
  padding: 2rem 0;
}

.entry form a{
  color:#08326B;
}

.entry strong{
  padding:2rem 0 0;
  color:#ca2222;
}


/*** 6. Modals ***/

/* close modal button */
dialog h1 > button{
  padding: 0 !important;
  background-color:transparent !important;
  color: #08326B;
  border:none ;
  margin:-0.1rem 0 0 !important;
  width: auto !important;
  float: right !important;
}

/* close button imaghe */
dialog h1 button svg{
  width:2rem !important;
  height:2rem !important;
  color: #08326B;
}

/* general modal */
dialog{
  width: 90%;
  max-height: 75%;
  min-height: 50%;
  box-shadow: 0 0 0 99999px rgb(0 0 0 / 50%);
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  padding:0 0 1rem;
  margin:0;
  z-index:5;
  border-radius: 0.2em;
  border: 0.08rem #08326B solid;
  -webkit-appearance: none;
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
}

dialog h1{
  font-size: 1.6rem;
  padding:1.2rem 2rem;
  margin: 0 0 2rem;
  border-bottom: 0.2rem solid #08326B;
  position: sticky;
  z-index: 6;
  background-color: #FFF;
  top:0;
  left:0;
}

dialog h2,
dialog p{
  padding:0.5rem 2rem 1rem;
  margin: 0;
}

dialog input,
dialog select,
dialog textarea,
dialog label{
  float: left;
  margin:0.5rem 2rem 3rem !important;
  width: calc(100% - 9rem);
}

dialog label{
  margin:0 0 0.5rem 2rem !important;
  width: auto;
}

dialog input,
dialog select{
  padding: 0.5rem 1rem 0.5rem 0.2rem !important; 
  border: none;
  border-bottom: 0.1rem solid #08326B;
}

dialog input[type="checkbox"]{
  width:auto !important;
  transform: scale(1.5);
  vertical-align: middle;
  float: none !important;
}

dialog input[type="submit"],
dialog input[type="button"]{
  border: none;
  border-radius: 0.2rem;
  background-color: #08326B;
  color:#FFF;
  padding: 1rem !important;
  width: calc(100% - 6rem) !important;
  margin: 0 0 0 2rem !important;
  max-width: none !important;
  float: none;
}

dialog input[type="submit"]:hover,
dialog input[type="button"]:hover{
  background-color: #17487d;
}

dialog input[type="button"]{
  width: auto !important;
  margin-right: 100%;
}

dialog dialog{
  width: 90%;
  height: 100%;
  max-height: auto;
  min-height: auto;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 60;
  transform: translateZ(60);
}

dialog dialog label{
  margin-top:1.5rem !important;
}


/*** 7. Add Rider ***/
.addPT1{
  width: 100%;
}

.addPT1 .noMHId{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.addPT1 .noMHId p{
  background-color: #f0c1c1;
  padding: 5rem;
  font-weight: bold;
  font-size: 1.4rem;
  width: 60%;
  border-radius: 0.5rem;
}

.addPT1 .skipFileUpload{
  background-color: #08326B;
  color:#FAFAFA;
  padding: 1rem 2rem;
  margin: 2rem 2rem 2rem 0;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 0.16rem;
  font-size: 1.5rem;
  float: right;
}

.addPT1 .skipFileUpload svg{
  width: 2rem;
  height: 2rem;
}

.steps{
  width:96%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin:1rem 2%;
}

.steps li{
  background-color: #08326B;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  width: auto;
  color:#FAFAFA;
  font-weight: bold;
}

.steps .selectedStep{
  background: #97BB3D;
  color:#08326B;
}

.fileUpload{
  display: none;
}


.fileUploadLabel{
  float: left;
  width: 90%;
  cursor: pointer;
  border: 0.2rem dashed #575757;
  border-radius: 0.5rem;
  background-color: #dae1e8;
  padding:10rem 0;
  margin:5rem 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fileUploadLabel svg{
  width: 5rem;
  height: 5rem;
}

.pt1Validate{
  display: flex;
  flex-direction: column;
  height: 100%;
  width:96%;
  margin:0 2%;
}

.pt1Validate > article{
  overflow:auto;
  width: 100%;
}

.addPT1Form form{
  width: 98%;
}

.addPT1Form form > fieldset{
  width: 96%;
  padding: 2%;
  margin: 5% 0;
  border: solid 0.16rem #08326B;
  border-radius: 0.2rem;
}

.addPT1Form form select,
.addPT1Form form input,
.addPT1Form form textarea{
  font-size: 1rem;
}

.addPT1Form form > fieldset:nth-child(4){
  display: flex;
  flex-direction: column;
}

.addPT1Form form > fieldset:nth-child(4) select{
  height: 14rem;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.addPT1Form form > fieldset:nth-child(4) p,
.addPT1Form form > fieldset:nth-child(4) li{
  color: #575757;
  line-height: 125%;
  font-size: 0.8rem;
}

.addPT1Form form > fieldset:nth-child(4) li{
  list-style:circle;
  margin-left: 1rem;
}

.addPT1Form form input{
  width:60%;
}

.addPT1Form form input[type="checkbox"]{
  clear: none;
  float: left;
  min-width: auto;
  /* width: 4%; */
  margin: 0 0.5rem 3rem 0;
  vertical-align: middle;
}

.addPT1Form form > input,
.addPT1Form form > select,
.addPT1Form form > textarea,
.addPT1Form form > fieldset > input,
.addPT1Form form > fieldset > select,
.addPT1Form form > fieldset > textarea,
.addPT1Form form > fieldset > fieldset,
/* section 5 */
.addPT1Form form > fieldset:nth-child(5) input,
.addPT1Form form > fieldset:nth-child(5) select{
  width: auto;
  min-width: 60%;
  margin: 0 0 3rem;
  float: left;
  clear: both;
}


.addPT1Form form > label,
.addPT1Form form > fieldset > label,
.addPT1Form form > fieldset > legend,
.addPT1Form form > fieldset > fieldset label,
/* section 5 */
.addPT1Form form > fieldset:nth-child(5) label,
.addPT1Form form > fieldset:nth-child(5) fieldset label,
.addPT1Form form > fieldset:nth-child(5) legend{
  margin: 0 0 1.25rem;
  clear: both;
}

.addPT1Form form > fieldset > label em{
  width: 95%;
  float: right;
  line-height: 125%;
  margin-top: -0.5rem;
}

.addPT1Form form .radioContainer{
  margin-bottom: 1rem !important;
  display: flex;
  justify-content: flex-start;
}
.addPT1Form form .radioContainer legend{
  margin-bottom: 1.5rem;
}

.addPT1Form form .radioContainer input{
  width: auto;
  transform: scale(1.5);
  padding: 1rem;
  vertical-align: middle;
  min-width: auto !important;
}
.addPT1Form form .radioContainer label{
  padding: 0.1rem 4rem 0.1rem 1rem;
  vertical-align: middle;
}


.pt1Validate .zoomTools{
  flex-direction: row;
  height:10.5rem;
  display: flex;
  background-color: #08326B;
}

.pt1Validate .zoomTools button{
  background-color: #08326B;
  border: none;
  border-radius: 0.16rem;
  padding: 1rem;
}

.pt1Validate .zoomTools svg{
  color: #FAFAFA;
  height:1.5rem;
  width:1.5rem;
}

.addPT1 .pt1Confirm{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.addPT1 .pt1Confirm h1{
  font-size: 3rem;
  font-weight: bold;
  margin: 2rem 0;
}

.addPT1 .pt1Confirm > svg{
  width:15rem;
  height: 15rem;
  color: #08326B;
}

.addPT1 .pt1Confirm > button{
  background-color: #08326B;
  color:#FAFAFA;
  padding: 1rem 2rem;
  margin: 2rem 0;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 0.16rem;
  font-size: 1.5rem;
}

.addPT1 .pt1Confirm > button + button{
  background-color: #88a53e;
}

.addPT1 .pt1Confirm > button svg{
  color:#FAFAFA;
  width: 2rem;
  height: 2rem;
}

/*** 8. Rider Dashboard ***/
.riderDashboard {
  width: 94%;
}
.riderDashboard .steps{
  width:66.66%;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}

.riderDashboard .steps li{
  width:49.9% !important;
}

.riderPT1s{
  display: flex;
  flex-wrap: wrap;
}

.riderDashboard > section > section:nth-child(1){
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.riderDashboard > section > section:nth-child(1) h1{
  color: #08326B;
  font-size: 1.8rem;
  font-weight: bold;
  margin-left:0.33%;
}


.riderDashboard > section > section:nth-child(1) button{
  background-color: #08326B;
  color:#FFF;
  border: none;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  padding: 1rem;
  margin-right:0.33%;
}

.pt1Box{
  width:30.33%;
  height: 12rem;
  margin: 1rem 0.5%;
  padding:1%;
  background-color: #FFF;
  display: flex;
  align-items: center;
  box-shadow: 5px 5px 5px -2px rgb(8 50 107 / 10%);
}

.pt1Box section:nth-child(1){
  width: 48%;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: auto;
}

.pt1Box section:nth-child(1) > svg:nth-child(1){
  width: 7rem;
  height: 7rem;
  color: #08326B;
  padding: 0;
}

.pt1Box section:nth-child(1) button{
  background-color: #97BB3D;
  color:#FFF;
  border: none;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin-top: 0.5rem;
}

.pt1Box section:nth-child(1) button svg{
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
}

.pt1Box section:nth-child(2) li{
  margin: 0.625rem 0;
  line-height: 125%;
}

.pt1BoxExpired{
  background-color: #f0c1c1;
}

.viewFullPT1{
  display: flex;
  flex-direction: column;
  width: 50%;
  height: calc(100% - 3rem);
  margin:2rem auto 0;
  background-color: #FFF;
  box-shadow: 5px 5px 5px -2px rgb(8 50 107 / 10%);
}

.viewFullPT1 > h1 {
  font-size: 1.8rem;
  background-color:#08326B;
  color:#FFF;
  padding:1rem 2rem;
}

.viewFullPT1 section {
  margin: 1rem 2rem;
}

.viewFullPT1 section > h1{
  font-size: 1.5rem;
  color:#08326B;
  border-bottom: 0.1rem solid #08326B;
  padding: 0.25rem 0;
  margin: 0.5rem 0;
}

.viewFullPT1 section > p{
  line-height: 125%;
}


/* trips section */
.riderItinsPanel > ul:first-child input{
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
  font-size: 1rem !important;
  background-color: #FFF !important;
  color: #000 !important;
  border: 0.1rem solid #08326B;
}

.riderItinsPanel > ul:first-child .subSelected {
  background-color: #97BB3D !important;
  color: #FFF !important;
  border: 0.1rem solid #97BB3D;
}

.riderItinsPanel > ul:first-child{
  margin: 1% 0 0 !important;
}

.riderItinsPanel .bookRider{
  background-color: transparent;
  color:#000;
  font-weight: normal;
  text-decoration: underline;
}

.riderItinsPanel .bookRider:hover{
  background-color: transparent;
}

.riderItinsPanel .openPT1 button{
  background-color: transparent;
  color: #08326B;
  text-decoration: underline;
  font-style: normal;
  display: flex;
  align-items: center;
  border-radius: 0;
  padding: 0;
  margin: 0;
}

.riderItinsPanel .openPT1 button svg{
  width: 1rem;
  height: 1rem;
  padding-left: 0.5rem;
}

/*** 9. PT1 Selection ***/
.pt1Select{
  width: 72%;
  background-color: #FFF;
  padding: 2%;
  margin-top: 2rem;
  border-radius: 0.2em;
  border-top: 0.2em solid #08326b;
}

.pt1Select >section:nth-child(1){
  width: 100%;
  /* display: flex; */
}

.pt1Select >section:nth-child(2){
  margin: 2rem 0;
  width: 100%;
}

.pt1Select >section:nth-child(1) h1{
  font-size: 2rem;
    padding: 0 0 1rem;
    border-bottom: 0.1rem solid #08326b;
    width: 100%;
}

.pt1Select >section:nth-child(1) button{
  background-color: #08326B;
  color: #FFF;
  box-shadow: none;
  border: none;
  padding:1rem;
  border-radius: 0.2rem;
  margin: 0 0 2rem;
}

.pt1Select .pt1Box{
  width: 40%;
  margin-right: 4%;
  background-color: #FCFCFC;
  height: 14rem;
}

.pt1Select>section>section:first-child h1 {
  color: #08326b;
  font-size: 1.5rem;
  font-weight: 700;
  margin-left: .33%;
}

.pt1Select .pt1Box button:nth-child(3){
  background-color: #08326B;
}

.pt1Select .pt1Selected{
  border: 0.1rem solid #08326B;
}

.pt1Select > article{  
  margin: 0 auto 2rem 0;
  padding-left: 1rem;
}

.pt1Select > article h1,
.pt1Select > article ul {
  color:#ca2222;
  font-weight: bold;
  line-height: 125%;
}

.pt1Select > article ul li{
  list-style:circle;
}

.pt1Select #bookingFormSubmit{
  background-color: #08326B;
  color:#FFF;
  border:none;
  border-radius: 0.2rem;
  padding:1.2rem 3rem;  
  margin-right:auto;
  width: 90%;
}

#bookingFormSubmit{
  height: 5rem;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 3rem;
}

#bookingFormSubmit:hover{
  background-color: #17487d;
}
/*** 10. Desktop ***/
@media only screen and (min-width: 768px) {
  body{
    overflow: hidden;
  }

  main{
    display: flex;
    justify-content: center;
    width: 70%;
    height: calc(100% - 4.5rem);
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #FAFAFA;
  }
  
  /** 2.1 Nav  **/
  main, 
  nav{
    width:77%;
  }

  /** 3. Dashboard**/
  .dashboard{
    width: 96%;
    display: flex;
    justify-content: flex-start;
    margin:0 2%;
  }

  /** 3.1 Left Panel**/
  .riderPanel{
    width:23%;
    height: 100vh;
    background-color:#08326B ;
  }

  /* List of riders */
  .riderPanel ul{
    height: calc(100% - 8.23rem); /* rem val is header + button height*/
  }

  /* Book rider button */
  .riderPanel li button{
    padding:1rem;
  }

  /* Add rider button*/
  .riderPanel > button{
    width:100%;
    padding:1rem 2rem;
    font-size:1.5rem;
    border: none;
    border-radius: 0;
  }

  .riderPanel #toggleRiderPanel{
    display: block;
    position: absolute;
    z-index: 5;
    width:1.5rem;
    top:0%;
    left:23%;
    padding: 0.3rem 0rem;
    background-color: #17487d;
    /* border: solid 0.016rem #FFF; */
  }
  .riderPanel #toggleRiderPanel svg{
    width:1.5rem;
    height:3.5rem;
    transform: rotate(90deg);
  }

  /** 3.2 Middle Panel**/
  .itinsPanel{
    width:100%;
    height: 100%;
  }

  .itinsPanel > ul:first-child input{
    padding:1.5rem 2.5rem;
    font-size:1.2rem;
  }

  .itinsPanel table{
    font-size: 1rem;
  }

  /* info button */
  .itinsPanel button{
    font-size: 1rem;
    padding:0.2rem 0.7rem;
  }

  .detailsModal > button{
    padding: 1rem 1.2rem;
  }


  .detailsModal .locationFields button{
    margin: -1rem 2rem 2rem 2rem;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;
    border-radius: 0.2rem;
  }

  /** 4. Partner Form **/
  .form form{
    width: 60%;
    max-width:40rem;
  }

  .partnerForm dialog,
  .recurringTripsForm dialog{
    top: 50%; 
  } 

  .bookingForm .phones{
    display: block;
  }

  .bookingForm .phones input,
  .bookingForm .phones label{
    width: 50%;
    float: left;
    clear: none;
  }
  .bookingForm .phones label:nth-child(1),
  .bookingForm .phones input:nth-child(3),
  .bookingForm .phones label:nth-child(5),
  .bookingForm .phones input:nth-child(7){
    width: 68%;
  }
  .bookingForm .phones label:nth-child(2),
  .bookingForm .phones input:nth-child(4),
  .bookingForm .phones label:nth-child(6),
  .bookingForm .phones input:nth-child(8){
    width: 25%;
    margin-left: 2%;
    padding-right:0;
  }
  .bookingForm .phones label:nth-child(2),
  .bookingForm .phones label:nth-child(6){
    padding-left: 1rem;
  }

  /** 6. Modals **/
  dialog{
    width: 50%;
  }

  /** 7. Add rider  **/
  .pt1Validate{
    flex-direction: row;
    overflow-y: auto;
    height: 88%;
    width:96%;
    margin:0 2%;
  }  

  .pt1Validate > article{
    width: calc(45% - 3.5rem);
    top:0;
    position:sticky;
  }
  .pt1Validate .zoomTools{
    position: sticky;
    top:0;
    flex-direction: column;
  }

  .pt1Validate .react-pdf__Document{
    margin:0 auto !important;
    width: 100% !important;
  }

  .addPT1Form{
    /* width: calc(75% - 3.5rem); */
    flex:2;
    margin-left: auto;
  }
  .addPT1Form form{
    width: 98%;
    margin: 0;
  }

}

/*** 11. Tickets ***/
#ticket{
  width: 59.1em;
  border: 0.1em solid #000;
  margin:0 auto
}

#ticket:nth-child(2){
  margin-top:2%;
}

#ticket section{
  padding:2%;
  width: 96%;
}

#ticket > section:nth-child(1){
  background-color: #08326B;
  color: #FFF;
  display: flex;
  justify-content: space-between;
}

#ticket > section:nth-child(1) h1{
  font-weight: bold;
  text-decoration: underline;
}

#ticket > section:nth-child(2) section{
  width: 48%;
  float: left;
  display: flex;
  flex-direction: column;
  padding:2% 0;
}

#ticket > section:nth-child(2) h1{
  padding: 0 0 1.25%;
  font-weight: bold;
}

#ticket > section:nth-child(3){
  display: flex;
  justify-content: space-between;
}

.ticketHolder{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.ticketHolder > input{
  margin:1em;
  padding:0.5rem;
  background-color: #08326B;
  color:#FFF;
  border: none;
  border-radius: 0.2em;
}

@media print{

  #ticket{
    width: 59.1em;
    border: 0.1em solid #000;
    margin:0 auto;
    -webkit-print-color-adjust: exact !important;
    -moz-print-color-adjust: exact !important;
  }
  
  #ticket section{
    padding:2%;
    width: 96%;
  }
  
  #ticket > section:nth-child(1){
    background-color: #08326B !important;
    color: #FFF !important;
    border-bottom: 0.1rem solid #08326B;
    display: flex;
    justify-content: space-between;
  }
  
  #ticket > section:nth-child(1) h1{
    font-weight: bold;
    text-decoration: underline;
    color:#FFF !important;
  }

  #ticket > section:nth-child(1) span{
    color:#FFF !important;
  }
  
  #ticket > section:nth-child(2) section{
    width: 48%;
    float: left;
    display: flex;
    flex-direction: column;
    padding:2% 0;
  }
  
  #ticket > section:nth-child(2) h1{
    padding: 0 0 1.25%;
    font-weight: bold;
  }
  
  #ticket > section:nth-child(3){
    display: flex;
    justify-content: space-between;
  }

  .ticketHolder > input{
    display: none;
  }
  
  .viewFullPT1{
    width: 100%;
  }
}
